import PageHeader from 'components/shared/PageHeader';
import ApmSiteIdInput from 'components/shared/ApmSiteIdInput';
import SmallWrapper from 'components/shared/SmallWrapper';

const Input = () => {
	return (
		<>
			<PageHeader heading={'Update Product Tier'} subheading={"Update a site's product tier and view page count info."} />
			<SmallWrapper>
				<ApmSiteIdInput redirectUrl={'/site/tier'} />
			</SmallWrapper>
		</>
	);
};

export default Input;
