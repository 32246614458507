import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Confirmation from 'components/shared/Confirmation';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import useSWR from 'swr';
import { useState } from 'react';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import PageHeader from 'components/shared/PageHeader';
import { Panel } from '../Components';
import { Button } from 'react-bootstrap';

const Collections = styled.div`
	display: grid;
	gap: 1.5rem;
`;

const CollectionTile = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
`;

const Content = styled.div`
	display: grid;
	gap: 1rem;
	padding: 1.5rem;
`;

const Field = styled.div`
	color: var(--bs-secondary);
	font-size: 0.9rem;
`;

const Value = styled.div``;

const Actions = styled.div`
	border-top: 1px solid #eee;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;
	padding: 1.5rem;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 0.5rem;
	}
`;

const ActionRow = styled.div`
	display: grid;
	justify-items: start;
	gap: 0.5rem;

	> button,
	a {
		width: 15rem;
	}
`;

const ViewCollections = () => {
	// State
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [collectionName, setCollectionName] = useState('');
	const [confirmFunction, setConfirmFunction] = useState('');
	const [confirmMessage, setConfirmMessage] = useState('');

	// Hooks
	const { site_id } = useParams();
	const { data: collectionData, isLoading, isValidating, mutate } = useSWR(`/api/v1/collections/${site_id}/all`);
	const { setMessage } = useMessage();

	// Handlers
	const handleCreatePayload = () => {
		const { fields } = collectionData.find(collection => collection.name === collectionName);
		const output = [];

		const propertyName = fields.find(field => field.name === 'property_name' && field.type === 'plain_text');
		const mainImage = fields.find(field => field.name === 'main_image' && field.type === 'image');
		const imageGallery = fields.find(field => field.name === 'image_gallery' && field.type === 'collection');

		// If property name does not exist, add to payload
		if (!propertyName) output.push({ name: 'property_name', type: 'plain_text' });

		// If main image does not exist, add to payload
		if (!mainImage) output.push({ name: 'main_image', type: 'image' });

		// If image gallery does not exist, add to payload
		if (!imageGallery)
			output.push({
				name: 'image_gallery',
				type: 'collection',
				inner_collection_fields: [
					{
						name: 'image',
						type: 'image'
					},
					{
						name: 'title',
						type: 'text'
					},
					{
						name: 'description',
						type: 'text'
					},
					{
						name: 'link',
						type: 'link'
					},
					{
						name: 'alt-text',
						type: 'text'
					},
					{
						name: 'link-text',
						type: 'text'
					}
				]
			});

		return output;
	};

	const handleDuplicateCollection = async () => {
		setShowConfirmation(false);
		try {
			await fetcher(`/api/v1/collections/duplicate/${site_id}/${encodeURIComponent(collectionName)}`, { method: 'POST' });
			await mutate();
			setMessage({ variant: 'success', text: `Collection name ${collectionName} successfully duplicated.` });
		} catch (error) {
			setMessage({ variant: 'danger', text: `Error duplicating collection name ${collectionName} - ${error.message}.` });
		}
	};

	const handleShowConfirmation = name => {
		setCollectionName(name);
		setShowConfirmation(true);
	};

	const handleCreateImageFields = async () => {
		setShowConfirmation(false);

		try {
			const payload = handleCreatePayload();
			await fetcher(`/api/v1/collections/fields/add/${site_id}/${encodeURIComponent(collectionName)}`, { method: 'POST', body: JSON.stringify(payload) });
			await mutate();
			setMessage({ variant: 'success', text: `Image fields successfully created for ${collectionName}.` });
		} catch (error) {
			setMessage({ variant: 'danger', text: `Error creating image fields - ${error.message}.` });
		}
	};

	// Maps
	const confirmFunctionMap = {
		duplicate: handleDuplicateCollection,
		createFields: handleCreateImageFields
	};

	// Required for image upload
	const requiredFields = ['property_name', 'main_image', 'image_gallery'];

	return (
		<>
			{(isLoading || isValidating) && <LoadingOverlay />}
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={confirmMessage} confirmFunction={confirmFunctionMap[confirmFunction]} />
			<PageHeader heading={'View Collections'} subheading={`View all collections for site ${site_id}.`} />
			<Collections>
				{collectionData?.length > 0 ? (
					collectionData?.map(collection => (
						<CollectionTile key={collection.name}>
							<Content>
								<div>
									<Field>Collection Name:</Field>
									<Value>
										<strong>{collection.name}</strong>
									</Value>
								</div>
								<div>
									<Field>Item Count:</Field>
									<Value>{collection.values.length}</Value>
								</div>
								<div>
									<Field>Field Names:</Field>
									<Value>{collection.fields.map(field => field.name).join(', ')}</Value>
								</div>
							</Content>
							<Actions>
								<ActionRow>
									<small className='text-muted'>View this collection's data in a table & duplicate rows:</small>
									<Button as={Link} to={`/collections/view/${site_id}/${collection.name}`}>
										View Data <i className='fas fa-eye'></i>
									</Button>
								</ActionRow>
								<ActionRow>
									<small className='text-muted'>Duplicate this collection's data into an entirely new collection:</small>
									<Button
										variant='outline-primary'
										onClick={() => {
											handleShowConfirmation(collection.name);
											setConfirmMessage(`duplicate ${collection.name}?`);
											setConfirmFunction('duplicate');
										}}>
										Duplicate <i className='fas fa-copy'></i>
									</Button>
								</ActionRow>
								<ActionRow>
									<small className='text-muted'>Add data to the end of this collection, while keeping existing data intact:</small>
									<Button variant='outline-primary' as={Link} to={`/collections/data/${site_id}/${collection.name}`}>
										Add Data <i className='fas fa-plus-circle'></i>
									</Button>
								</ActionRow>
								{requiredFields.every(requiredField => collection.fields.map(field => field.name).includes(requiredField)) ? (
									<ActionRow>
										<small className='text-muted'>Add images & allow customers to add images to existing rows in this collection:</small>
										<Button variant='outline-primary' as={Link} to={`/collections/images/${site_id}/${collection.name}`}>
											Add Images <i className='fas fa-images'></i>
										</Button>
									</ActionRow>
								) : (
									<ActionRow>
										<small className='text-muted'>Creates new fields to support bulk image uploading for this collection:</small>
										<Button
											variant='outline-primary'
											onClick={() => {
												handleShowConfirmation(collection.name);
												setConfirmMessage(`create new image fields for ${collection.name}?`);
												setConfirmFunction('createFields');
											}}>
											Create Image Fields <i className='fas fa-images'></i>
										</Button>
									</ActionRow>
								)}
							</Actions>
						</CollectionTile>
					))
				) : (
					<Panel>
						No collections found in site <strong>{site_id}</strong>.
					</Panel>
				)}
			</Collections>
		</>
	);
};

export default ViewCollections;
