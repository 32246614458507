export const sortCollectionFields = (fields, values) => {
	return values.map(value => {
		const keys = Object.keys(value.data);
		keys.sort((a, b) => fields.indexOf(a) - fields.indexOf(b));
		return fields.map(field => value.data[field] ?? null);
	});
};

export const formatCollectionData = data => {
	const headers = data?.fields?.map(field => field.name);
	const values = headers && sortCollectionFields(headers, data?.values);
	const { name } = { ...data };
	return { name, headers, values };
};
