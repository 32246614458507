import { Panel } from 'components/Collections/Components';
import CollectionTable from 'components/shared/CollectionTable';
import styled from 'styled-components';

const Component = styled.div``;

const Verify = ({ importedCsv }) => {
	// Props
	const headers = Object.keys(importedCsv[0]);
	const values = importedCsv.map(item => Object.values(item));
	const count = headers.length;

	return (
		<Component>
			<Panel className='mb-4'>
				<h5>
					<i className='fas fa-info-circle text-warning me-3'></i>Instructions
				</h5>
				<ol className='mb-0'>
					<li>Verify that all data in the table below is accurate and correctly formatted.</li>
					<li>Once verified, click the Add new data button.</li>
				</ol>
			</Panel>
			<CollectionTable headers={headers} values={values} count={count} />
		</Component>
	);
};

export default Verify;
