import { useState } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import Confirmation from 'components/shared/Confirmation';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import SmallWrapper from 'components/shared/SmallWrapper';
import SiteInfoCard from 'components/shared/SiteInfoCard';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

const SiteInfo = styled.form`
	display: grid;
	gap: 1.5rem;

	@media (max-width: 576px) {
		gap: 1rem;
	}
`;

const Details = () => {
	// State
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [database, setDatabase] = useState('');
	const [propertyGroup, setPropertyGroup] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	// Hooks
	const { site_id } = useParams();
	const { data: siteInfo, isValidating } = useSWR(`/api/v1/sites/${site_id}`);
	const { setMessage } = useMessage();

	// Handlers
	const checkDatabase = async () => {
		const { database: db_name, is_valid } = await fetcher(`/api/v1/listings/database/${database}`);
		if (!is_valid) {
			throw new Error(`${db_name} is not a valid AppFolio database name.`);
		}
	};

	const pushEndpoint = async () => {
		// Serialize payload
		const site_id = siteInfo.site_name;
		const endpoint = `https://${database}.appfolio.com/listings/duda_api/v1/listings`;
		const published = siteInfo.publish_status === 'PUBLISHED' ? true : false;
		const property_group = propertyGroup ? encodeURIComponent(propertyGroup).toLowerCase() : null;
		const is_demo = false;
		const body = { site_id, database, endpoint, property_group, is_demo, publish_status: published };

		// PUT new details
		await fetcher('/api/v1/listings/', { method: 'PUT', body: JSON.stringify(body) });
	};

	const handleUpdateDatabase = async () => {
		setShowConfirmation(false);
		setIsLoading(true);
		try {
			await checkDatabase();
			await pushEndpoint();
			await mutate(`/api/v1/sites/${site_id}`);
			setMessage({
				variant: 'success',
				text: `Database for site ${siteInfo.site_name} was updated successfully.`
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: `The database for site ${siteInfo.site_name} was not updated - ${error.message}`
			});
		}
		setDatabase('');
		setPropertyGroup('');
		setIsLoading(false);
	};

	return (
		<>
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={`update the database to ${database}.appfolio.com`} confirmFunction={handleUpdateDatabase} />
			<PageHeader heading={'Update Database'} subheading={'Update the database.'} />
			{(isLoading || isValidating) && <LoadingOverlay />}
			<SmallWrapper>
				{siteInfo && (
					<SiteInfo
						onSubmit={e => {
							e.preventDefault();
							setShowConfirmation(true);
						}}>
						<SiteInfoCard siteInfo={siteInfo} />
						<div>
							<Form.Label>New Database Name:</Form.Label>
							<InputGroup>
								<Form.Control type='text' value={database} onChange={e => setDatabase(e.target.value)} required />
								<InputGroup.Text className='border-0'>.appfolio.com</InputGroup.Text>
							</InputGroup>
						</div>
						<div>
							<Form.Label>New Property Group / Portfolio Name (optional):</Form.Label>
							<Form.Control type='text' value={propertyGroup} onChange={e => setPropertyGroup(e.target.value)} pattern='[a-zA-Z0-9 ]+' className='mb-1' />
							<Form.Text>Only alphanumeric characters are allowed (no special characters)</Form.Text>
						</div>
						<div>
							<Button type='submit'>
								Update Database <i className='fas fa-paper-plane'></i>
							</Button>
						</div>
					</SiteInfo>
				)}
			</SmallWrapper>
		</>
	);
};

export default Details;
