import styled from 'styled-components';

const Component = styled.div`
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	border-radius: 0.5rem;
	overflow: hidden;
`;

const Header = styled.h2`
	background: var(--bs-dark);
	color: white;
	font-size: 1.5rem;
	padding: 1rem 1.25rem;
	margin: 0;
`;

const Body = styled.div`
	padding: ${({ $padding }) => ($padding ? '1.5rem' : 0)};
	background: white;
`;

const Card = ({ title, padding = true, children, ...props }) => {
	return (
		<Component {...props}>
			<Header>{title}</Header>
			<Body $padding={padding}>{children}</Body>
		</Component>
	);
};

export default Card;
