import LoadingOverlay from 'components/shared/LoadingOverlay';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useMessage } from 'utils/MessageContext';
import ModalMessage from './ModalMessage';
import { nanoid } from 'nanoid';
import FileCard from './FileCard';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const Form = styled.form`
	padding: 1.5rem;
	display: grid;
	gap: 1.5rem;
	position: relative;
`;

const CloseButton = styled.button`
	all: unset;
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	font-size: 1.25rem;
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
	display: grid;
	place-items: center;
	transition: 0.2s;

	&:hover,
	&:focus-visible {
		background: var(--lightest);
	}
`;

const Icon = styled.i`
	font-size: 5rem;
`;

const UploadZone = styled.div`
	border: 3px dashed var(--bs-gray-400);
	border-radius: 0.5rem;
	padding: 2rem;
	display: grid;
	place-items: center;
	min-height: 200px;
	margin: 0 auto;
	cursor: pointer;
	background: var(--lightest);
	color: var(--bs-gray-600);
`;

const UploadInput = styled.input`
	display: none;
`;

const Wrapper = styled.div`
	display: grid;
`;

const Submit = styled(Button)`
	justify-self: stretch;
`;

const FloorPlanUploadModal = ({ show, setShow, rowId, mutate, existingCount }) => {
	// State
	const [filesToUpload, setFilesToUpload] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [modalMessage, setModalMessage] = useState(null);

	// Hooks
	const { site_id, collection_name } = useParams();
	const { setMessage } = useMessage();

	// Handlers
	const handleRemoveFloorPlan = id => {
		setFilesToUpload(prev => prev.filter(file => file.id !== id));
	};

	const handleFloorPlanSelect = e => {
		const { files } = e.target;

		// Too many files
		if (files.length + existingCount > 10) {
			setModalMessage({ variant: 'danger', text: `Please only select up to ${10 - existingCount} floor plans.` });
			return;
		}

		// File too large
		if ([...files].some(file => file.size >= 2 * 1024 * 1024)) {
			setModalMessage({ variant: 'danger', text: 'One or more files are larger than 2MB.' });
			return;
		}

		// Success
		const filesWithIds = [...files].map(file => ({ id: nanoid(), file }));
		setFilesToUpload(filesWithIds);
	};

	const handleCloseModal = () => {
		setShow(false);
		setFilesToUpload([]);
		setIsLoading(false);
		setModalMessage(null);
		mutate();
	};

	const handleSubmitFloorPlans = async e => {
		try {
			e.preventDefault();
			setIsLoading(true);

			// Compile form body
			const body = new FormData();
			filesToUpload.forEach(floorPlan => body.append('floor_plans', floorPlan.file));

			// Fetch & parse response
			const response = await fetch(`/api/v1/upload/floor_plans/${site_id}/${encodeURIComponent(collection_name)}/${rowId}`, { method: 'POST', body });
			const data = await response.json();
			if (!response.ok) throw data;

			// Success message
			setMessage({
				variant: 'success',
				text: `Successfully uploaded ${filesToUpload.length} floor plans.`
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: `Error uploading floor plans - ${error.message}`
			});
		} finally {
			// Revoke URLs
			filesToUpload.forEach(file => URL.revokeObjectURL(file.file));

			setTimeout(() => {
				handleCloseModal();
				setIsLoading(false);
			}, 1000);
		}
	};

	// Handle reorder list
	const handleReorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	// Handle reorder list
	const handleDragEnd = result => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const newFilesToUpload = handleReorder(filesToUpload, result.source.index, result.destination.index);
		setFilesToUpload(newFilesToUpload);
	};

	return (
		<Modal show={show} onHide={handleCloseModal}>
			<ModalMessage data={modalMessage} setData={setModalMessage} />
			{isLoading && <LoadingOverlay className='position-absolute' />}
			<Form onSubmit={handleSubmitFloorPlans}>
				<CloseButton type='button' onClick={handleCloseModal}>
					<i className='fas fa-times'></i>
				</CloseButton>
				<div className='text-center'>
					<h4>Upload your floor plans</h4>
					<div className='text-muted text-center'>A maximum of {10 - existingCount} floor plans, each less than 2MB is allowed.</div>
				</div>
				<label htmlFor='floorplans' className='m-0'>
					<UploadZone>
						<Icon className='far fa-file-image'></Icon>
						<div className='text-center'>Click to select multiple files on your computer</div>
						<UploadInput id='floorplans' name='floorplans' type='file' accept='image/png, image/jpg, image/gif' multiple onChange={handleFloorPlanSelect} />
					</UploadZone>
				</label>
				{filesToUpload.length > 0 && !isLoading && (
					<DragDropContext onDragEnd={handleDragEnd}>
						<Wrapper>
							<h5 className='m-0'>Files to be uploaded:</h5>
							<Droppable droppableId='files'>
								{provided => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										{filesToUpload.map((file, i) => (
											<FileCard key={file.id} fileObject={file} index={i} mainImageId={''} handleRemoveFloorPlan={handleRemoveFloorPlan} />
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
							<Submit type='submit' disabled={isLoading}>
								Confirm upload <i className='fas fa-check-circle'></i>
							</Submit>
						</Wrapper>
					</DragDropContext>
				)}
			</Form>
		</Modal>
	);
};

export default FloorPlanUploadModal;
