import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import { useMessage } from 'utils/MessageContext';
import { Panel } from '../../Components';

const Component = styled.div``;

const CheckRow = styled.div`
	padding: 0.5rem;
	border-bottom: 1px solid #ddd;

	&.unchecked label {
		text-decoration: line-through;
	}
`;

const FieldWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
`;

const Configure = ({ csvFields, setCsvFields }) => {
	// Data
	const defaultCheckboxes = [
		{
			name: 'Property Name',
			checked: true,
			readOnly: true,
			id: nanoid()
		},

		{
			name: 'Description',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Property Address',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Property Group',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Rent Range',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Bed Range',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Bath Range',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Phone',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Email',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Application Fee',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Apartment Amenities',
			checked: true,
			id: nanoid()
		},
		{
			name: 'Community Amenities',
			checked: true,
			id: nanoid()
		}
	];

	const fieldSet = csvFields.length > 0 ? csvFields : defaultCheckboxes;

	// State
	const [fields, setFields] = useState(fieldSet);
	const [fieldName, setFieldName] = useState('');

	// Hooks
	const { setMessage } = useMessage();

	// Handlers
	const handleToggle = (check, i) => {
		const { name, checked, id } = check;
		const newFields = [...fields];
		newFields[i] = { name, checked: !checked, id };
		setFields(newFields);
	};

	const handleChange = e => {
		const { value } = e.target;
		setFieldName(value);
	};

	const handlePreventSubmit = e => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleAddField();
		}
	};

	const handleAddField = () => {
		const exists = csvFields.find(field => field.name.toLowerCase() === fieldName.toLowerCase());
		if (!exists) {
			setFields(prev => [...prev, { name: fieldName, checked: true, id: nanoid() }]);
			setFieldName('');
		} else {
			setMessage({ variant: 'danger', text: `Cannot add field with duplicate field name "${fieldName}"` });
		}
	};

	// Effects
	useEffect(() => {
		setCsvFields(fields.filter(field => field.checked));
	}, [fields, setCsvFields]);

	return (
		<Component>
			<Row>
				<Col lg={6}>
					<Panel className='mb-4'>
						<h5>
							<i className='fas fa-info-circle text-warning me-3'></i>Instructions
						</h5>
						<ol className='mb-0'>
							<li>Check or uncheck the fields to the right that you want to include in the csv template.</li>
							<li>Specify the names of any new fields below to be added to the csv template.</li>
						</ol>
					</Panel>
					<h5>Add new field</h5>
					<Form.Group>
						<Form.Label>Field name</Form.Label>
						<FieldWrapper>
							<Form.Control type='text' value={fieldName} onChange={e => handleChange(e)} onKeyDown={handlePreventSubmit} />
							<Button className='default-style' onClick={handleAddField}>
								<i className='fas fa-plus'></i>
							</Button>
						</FieldWrapper>
					</Form.Group>
				</Col>
				<Col lg={6}>
					<Form.Group>
						{fields.map((check, i) => (
							<CheckRow key={check.id} className={`${!check.checked && 'unchecked'}`}>
								<Form.Check type='checkbox' id={check.id} label={check.name} onChange={() => handleToggle(check, i)} checked={check.checked} disabled={check.readOnly} />
							</CheckRow>
						))}
					</Form.Group>
				</Col>
			</Row>
		</Component>
	);
};

export default Configure;
