import MessageBar from 'components/shared/MessageBar';
import NotFound from 'components/pages/NotFound';
import Footer from './Footer';
import { Routes, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';

// APM Listings
import ListingsDatabaseInput from 'components/Listings/Database/Input';
import ListingsDatabaseDetails from 'components/Listings/Database/Details';
import ListingsRefresh from 'components/Listings/Refresh';
import ListingsInitializeInput from 'components/Listings/Initialize/Input';
import ListingsInitializeDetails from 'components/Listings/Initialize/Details';

// APM Collections
import CollectionsExportCsvTemplate from 'components/Collections/Export/CsvTemplate';
import CollectionsImportCsvData from 'components/Collections/Import/CsvData';
import CollectionsModifyInput from 'components/Collections/Modify/Input';
import CollectionsModifyDataAddCsvData from 'components/Collections/Modify/Data/AddCsvData';
import CollectionsModifyImagesAddImages from 'components/Collections/Modify/Images/AddImages';
import CollectionsModifyView from 'components/Collections/Modify/View';
import CollectionsModifyViewCollections from 'components/Collections/Modify/ViewCollections';

// APM Site
import SiteTierInput from 'components/Site/Tier/Input';
import SiteTierDetails from 'components/Site/Tier/Details';
import SiteAudioEyeInput from 'components/Site/AudioEye/Input';
import SiteAudioEyeDetails from 'components/Site/AudioEye/Details';

// IM Database
import IMAccountNameInput from 'components/IM/Account Name/Input';
import IMAccountNameDetails from 'components/IM/Account Name/Details';

// IM AudioEye
import IMAudioEyeInput from 'components/IM/AudioEye/Input';
import IMAudioEyeDetails from 'components/IM/AudioEye/Details';

// Blog
import BlogFeed from 'components/Blog/Feed';

const Component = styled.main`
	grid-area: main;
	display: flex;
	flex-direction: column;
	overflow: hidden auto;
`;

const Wrapper = styled.div`
	max-width: 100%;
	width: 1280px;
	margin: 0 auto;
	padding: 2rem;

	@media (max-width: 767px) {
		padding: 1rem;
	}
`;

export default function Main() {
	return (
		<Component>
			<MessageBar />
			<Wrapper>
				<Routes>
					<Route index element={<Navigate to='listings/database' />} />
					<Route path='listings'>
						<Route path='database' element={<ListingsDatabaseInput />} />
						<Route path='database/:site_id' element={<ListingsDatabaseDetails />} />
						<Route path='refresh' element={<ListingsRefresh />} />
						<Route path='initialize' element={<ListingsInitializeInput />} />
						<Route path='initialize/:site_id' element={<ListingsInitializeDetails />} />
					</Route>
					<Route path='collections'>
						<Route path='export' element={<CollectionsExportCsvTemplate />} />
						<Route path='import' element={<CollectionsImportCsvData />} />
						<Route path='modify' element={<CollectionsModifyInput />} />
						<Route path='data/:site_id/:collection_name' element={<CollectionsModifyDataAddCsvData />} />
						<Route path='images/:site_id/:collection_name' element={<CollectionsModifyImagesAddImages />} />
						<Route path='view/:site_id' element={<CollectionsModifyViewCollections />} />
						<Route path='view/:site_id/:collection_name' element={<CollectionsModifyView />} />
					</Route>
					<Route path='site'>
						<Route path='tier' element={<SiteTierInput />} />
						<Route path='tier/:site_id' element={<SiteTierDetails />} />
						<Route path='audioeye' element={<SiteAudioEyeInput />} />
						<Route path='audioeye/:site_id' element={<SiteAudioEyeDetails />} />
					</Route>
					<Route path='im'>
						<Route path='account' element={<IMAccountNameInput />} />
						<Route path='account/:site_id' element={<IMAccountNameDetails />} />
						<Route path='audioeye' element={<IMAudioEyeInput />} />
						<Route path='audioeye/:site_id' element={<IMAudioEyeDetails />} />
					</Route>
					<Route path='blog'>
						<Route path='feed' element={<BlogFeed />} />
					</Route>
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Wrapper>
			<Footer />
		</Component>
	);
}
