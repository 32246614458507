import PageHeader from 'components/shared/PageHeader';
import ImSiteIdInput from 'components/shared/ImSiteIdInput';
import SmallWrapper from 'components/shared/SmallWrapper';

const Input = () => {
	return (
		<>
			<PageHeader heading={'Update Database'} subheading={'Connect a website to a new database.'} />
			<SmallWrapper>
				<ImSiteIdInput redirectUrl={'/im/account'} />
			</SmallWrapper>
		</>
	);
};

export default Input;
