import PageHeader from 'components/shared/PageHeader';
import ApmSiteIdInput from 'components/shared/ApmSiteIdInput';
import SmallWrapper from 'components/shared/SmallWrapper';

const Input = () => {
	return (
		<>
			<PageHeader heading={'Initialize Listings'} subheading={'Create a new appfolio-listings collection in a specific site.'} />
			<SmallWrapper>
				<ApmSiteIdInput redirectUrl={'/listings/initialize'} />
			</SmallWrapper>
		</>
	);
};

export default Input;
