import { useEffect } from 'react';
import styled from 'styled-components';

const Component = styled.div`
	text-align: center;
	padding: 1rem;
	background: ${({ $variant }) => `var(--bs-${$variant})`};
	color: white;
	box-shadow: inset 0 -3px 0 0 #fff6;

	i {
		opacity: 0.6;
	}
`;

const ModalMessage = ({ data, setData }) => {
	// Props
	const { variant, text } = { ...data };

	// Icon map
	const variantIcons = {
		success: 'fa-check-circle',
		warning: 'fa-exclamation-circle',
		danger: 'fa-times-circle',
		primary: 'fa-info-circle'
	};

	useEffect(() => {
		if (data) {
			const timeout = setTimeout(() => setData(null), 5000);
			return () => clearTimeout(timeout);
		}
	}, [data, setData]);

	return (
		data && (
			<Component $variant={variant}>
				<i className={`fas ${variantIcons[variant]} me-1`}></i> {text}
			</Component>
		)
	);
};

export default ModalMessage;
