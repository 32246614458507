import { Modal, Button } from 'react-bootstrap';

export default function Confirmation({ show, setShow, message, confirmFunction }) {
	return (
		<Modal show={show} onHide={() => setShow(false)} backdrop='static'>
			<Modal.Header className='justify-content-center'>
				<Modal.Title>Are you sure?</Modal.Title>
			</Modal.Header>
			<Modal.Body className='text-center'>
				<p>Please confirm that you would like to {message}</p>
				<Button variant='primary' className='me-2' onClick={() => confirmFunction()}>
					Yes, go ahead. <i className='fas fa-check-circle'></i>
				</Button>
				<Button variant='secondary' onClick={() => setShow(false)}>
					Nope, go back. <i className='fas fa-times-circle'></i>
				</Button>
			</Modal.Body>
		</Modal>
	);
}
