import styled from 'styled-components';
import parse from 'html-react-parser';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Table = styled.div`
	display: grid;
	background: white;
	border-radius: 0.5rem;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);

	&::-webkit-scrollbar {
		display: none;
	}
`;

const TableRow = styled.div`
	display: grid;
	grid-template-columns: ${({ $count, $isEditable }) => `repeat(${$count}, minmax(20rem, 1fr)) ${$isEditable ? '4rem' : ''}`};
	border-bottom: 1px solid var(--lightest);
`;

const TableHeader = styled(TableRow)`
	font-weight: bold;
`;

const TableCell = styled.div`
	padding: 0.75rem;
	border-right: 1px solid #eee;
	position: relative;
	max-height: 10.5rem;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const TableBody = styled(TableRow)`
	font-size: 0.9rem;
	line-height: 1.25;
	position: relative;
`;

const Gallery = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0.5rem;
`;

const Image = styled.img`
	width: 100%;
	aspect-ratio: 16/9;
	border-radius: 0.25rem;
	object-fit: cover;
	object-position: center;
`;

const EditWrapper = styled.div`
	position: sticky;
	right: 0;
	padding-top: 0.75rem;
	display: grid;
	justify-items: center;

	&.show {
		z-index: 9;
	}
`;

const EditButton = styled.button`
	all: unset;
	background: var(--lightest);
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 50%;
	display: grid;
	place-items: center;
	transition: 0.1s;
	border: 2px solid white;

	&:hover,
	&:focus-visible {
		background: var(--border);
	}

	&::after {
		display: none;
	}
`;

// Render functions
const CellValue = ({ value }) => {
	if (typeof value === 'string') {
		try {
			const url = new URL(value);
			const exts = ['.png', '.jpeg', '.jpg'];
			if (exts.forEach(ext => !url.pathname.includes(ext))) {
				throw new Error();
			}
			return <Image src={value} alt='' width='100%' loading='lazy' />;
		} catch (error) {
			return parse(value);
		}
	}
	if (Array.isArray(value)) {
		return (
			<Gallery>
				{value.map((image, i) => (
					<Image key={`${image.image}${i}`} src={image.image} alt={image.title} width='100%' loading='lazy' />
				))}
			</Gallery>
		);
	}
};

const CollectionTable = ({ headers, values, count, isEditable = false, handleDuplicateRow, site_id, collection_name }) => {
	// Required for image upload
	const requiredFields = ['property_name', 'main_image', 'image_gallery'];

	return (
		<Table>
			<TableHeader $count={count} $isEditable={isEditable}>
				{headers.map((headerValue, i) => (
					<TableCell key={`${headerValue}${i}`}>{parse(headerValue)}</TableCell>
				))}
			</TableHeader>
			{values.map((value, i) => (
				<TableBody $count={count} $isEditable={isEditable} key={`${value}${i}`}>
					{value.map((subValue, i) => (
						<TableCell key={`${subValue}${i}`}>
							<CellValue value={subValue} />
						</TableCell>
					))}
					{new Array(count - value.length).fill('').map((item, i) => (
						<TableCell key={`${item}${i}`} />
					))}
					{isEditable && (
						<Dropdown as={EditWrapper}>
							<Dropdown.Toggle as={EditButton} id={`edit-button-${i}`}>
								<i className='fas fa-ellipsis-v'></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => handleDuplicateRow(i)}>
									<i className='fas fa-copy me-1'></i> Duplicate row
								</Dropdown.Item>
								{requiredFields.every(requiredField => headers.includes(requiredField)) && (
									<Dropdown.Item as={Link} to={`/collections/images/${site_id}/${collection_name}`}>
										<i className='fas fa-images me-1'></i> Add Images
									</Dropdown.Item>
								)}
							</Dropdown.Menu>
						</Dropdown>
					)}
				</TableBody>
			))}
		</Table>
	);
};

export default CollectionTable;
