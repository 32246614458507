import styled from 'styled-components';

const Component = styled.div`
	max-width: 720px;
	display: grid;
	gap: 2rem;

	@media (max-width: 576px) {
		gap: 1rem;
	}
`;

const SmallWrapper = ({ children, ...props }) => {
	return <Component {...props}>{children}</Component>;
};

export default SmallWrapper;
