import styled from 'styled-components';
import { tidyUrl, tidyDate, tidyDatabase, tidyPropertyGroup } from 'utils/Tidy';
import Card from './Card';
import { getTemplateDetails } from 'utils/Templates';
import { getProductTier } from 'utils/Tiers';
import { useEffect, useState } from 'react';

const BodyWrapper = styled.div`
	display: grid;
	grid-template-columns: 20rem repeat(${({ $children }) => ($children ? '2' : '1')}, 1fr);
	gap: 1rem;
	justify-items: start;

	@media (max-width: 991px) {
		grid-template-columns: 100%;
		gap: 0;
	}
`;

const Thumb = styled.img``;

const Content = styled.div`
	padding: 1.5rem;
	display: grid;
	justify-items: start;
	align-content: start;
	gap: 1rem;
	max-width: 100%;
	overflow: hidden;
`;

const Label = styled.div`
	font-size: 0.9rem;
	color: var(--bs-secondary);
`;

const PublishStatus = ({ status }) => {
	if (status === 'PUBLISHED') {
		return (
			<div>
				<i className='fas fa-check-circle text-success me-2'></i>
				Published
			</div>
		);
	}
	if (status === 'NOT_PUBLISHED_YET') {
		return (
			<div>
				<i className='fas fa-times-circle text-danger me-2'></i>
				Not Published Yet
			</div>
		);
	} else {
		return (
			<div>
				<i className='fas fa-exclamation-circle text-warning me-2'></i>
				Unpublished
			</div>
		);
	}
};

const SiteInfoCard = ({ siteInfo, product = 'APM', children, ...props }) => {
	// Destructure props
	const { listings_endpoint, site_name, thumbnail_url, site_domain, creation_date, publish_status, template_id, preview_site_url, external_uid, im_account_name } = siteInfo;

	// Dependent data
	const currentDatabase = tidyDatabase(listings_endpoint);
	const currentPropertyGroup = tidyPropertyGroup(listings_endpoint);

	// State
	const [templateDetails, setTemplateDetails] = useState('');
	const [tierDetails, setTierDetails] = useState('');

	// Effects
	useEffect(() => {
		const getTemplate = async () => {
			const template = await getTemplateDetails(template_id);
			setTemplateDetails(template);
		};
		const getTier = async () => {
			const tier = await getProductTier(external_uid);
			setTierDetails(tier);
		};
		getTemplate();
		getTier();
	}, [external_uid, template_id]);

	return (
		<Card title={site_name} padding={false} {...props}>
			<BodyWrapper $children={children}>
				<Thumb src={thumbnail_url} width='100%' alt={site_domain} />
				<Content>
					<h5 className='m-0'>Site Info</h5>
					{site_domain && (
						<div>
							<Label>Live URL:</Label>
							<a href={`http://${site_domain}`} target='_blank' rel='noopener noreferrer'>
								{tidyUrl(site_domain)}
							</a>
						</div>
					)}
					<div>
						<Label>Created:</Label>
						<div>{tidyDate(creation_date, 'D MMMM YYYY')}</div>
					</div>
					<div>
						<Label>Status:</Label>
						<PublishStatus status={publish_status} />
					</div>
					{product === 'APM' && (
						<>
							<div>
								<Label>Product Tier:</Label>
								<div>{tierDetails.name}</div>
							</div>

							<div>
								<Label>Template:</Label>
								<div>{templateDetails.name}</div>
							</div>
							<div>
								<Label>Preview Site URL:</Label>
								<div>
									<a href={preview_site_url} target='_blank' rel='noopener noreferrer'>
										{tidyUrl(preview_site_url)}
									</a>
								</div>
							</div>
							<div>
								<Label>Connected APM Database:</Label>
								<div>{currentDatabase ?? '-'}</div>
							</div>
							<div>
								<Label>Connected APM Property Group:</Label>
								<div>{currentPropertyGroup ?? '-'}</div>
							</div>
						</>
					)}
					{product === 'IM' && (
						<div>
							<Label>Connected AppFolio IM Account Name:</Label>
							<div>{im_account_name ?? '-'}</div>
						</div>
					)}
				</Content>
				{children && <Content>{children}</Content>}
			</BodyWrapper>
		</Card>
	);
};

export default SiteInfoCard;
