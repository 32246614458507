import { useState } from 'react';
import { Accordion, Button, useAccordionButton } from 'react-bootstrap';
import styled from 'styled-components';
import ImageUploadModal from './Modals/ImageUploadModal';
import FloorPlanUploadModal from './Modals/FloorPlanUploadModal';

const Component = styled.div`
	display: grid;
	gap: 1rem;
`;

const Items = styled.div`
	display: grid;
	gap: 1rem;
`;

const Item = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	padding: 1rem 1.25rem;
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: start;
	gap: 1.5rem;

	@media (max-width: 991px) {
		grid-template-columns: 100%;
		justify-items: start;
	}
`;

const CustomToggleStyle = styled.button`
	all: unset;
	display: grid;
	grid-template-columns: 1.25rem 1fr auto;
	gap: 0.5rem;
	margin-top: 0.25rem;
	align-items: center;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(6.5rem, 1fr));
	gap: 0.5rem;
	margin: 0.75rem 0;

	a:hover,
	a:focus-visible {
		transition: 0.2s;
		filter: brightness(0.75);
	}

	img {
		width: 100%;
		height: 100%;
		aspect-ratio: 4 / 3;
		object-fit: cover;
	}
`;

const Buttons = styled.div`
	display: grid;
	gap: 0.5rem;
`;

const CustomToggle = ({ eventKey, ...props }) => {
	const handleClick = useAccordionButton(eventKey);
	return <CustomToggleStyle {...props} onClick={handleClick} />;
};

const ImageUploads = ({ data, mutate }) => {
	// Props
	const { values } = { ...data };

	// State
	const [showImageUploadModal, setShowImageUploadModal] = useState(false);
	const [showFloorPlanUploadModal, setShowFloorPlanUploadModal] = useState(false);
	const [rowId, setRowId] = useState('');

	const existingImageCount = values.find(item => item.id === rowId)?.data?.image_gallery?.length ?? 0;
	const mainImageExists = values.find(item => item.id === rowId)?.data?.main_image;
	const existingFloorPlanCount = values.find(item => item.id === rowId)?.data?.floor_plan_gallery?.length ?? 0;

	// Handlers
	const handleShowImageUploadModal = id => {
		setRowId(id);
		setShowImageUploadModal(true);
	};

	const handleShowFloorPlanUploadModal = id => {
		setRowId(id);
		setShowFloorPlanUploadModal(true);
	};

	return (
		<Component>
			<ImageUploadModal show={showImageUploadModal} setShow={setShowImageUploadModal} rowId={rowId} mutate={mutate} existingCount={existingImageCount} mainImageExists={mainImageExists} />
			<FloorPlanUploadModal show={showFloorPlanUploadModal} setShow={setShowFloorPlanUploadModal} rowId={rowId} mutate={mutate} existingCount={existingFloorPlanCount} />
			<Items>
				{values.map(item => (
					<Item key={item.id}>
						<div>
							<div>
								<span className='fw-bold'>{item.page_item_url}</span> <small className='text-muted'>({item.id})</small>
							</div>
							<Accordion>
								<CustomToggle eventKey={1}>
									<i className='fas fa-images text-muted'></i>
									<div>
										<strong>{item?.data?.image_gallery?.length ?? '0'}</strong> images
									</div>
									<i className='fas fa-angle-down text-muted'></i>
								</CustomToggle>
								{item?.data?.image_gallery?.length > 0 && (
									<Accordion.Collapse eventKey={1}>
										<Grid>
											{item?.data?.image_gallery?.map((image, i) => (
												<a href={image.image} target='_blank' rel='noopener noreferrer' key={i}>
													<img src={image.image} alt={image['alt-text']} />
												</a>
											))}
										</Grid>
									</Accordion.Collapse>
								)}
								<CustomToggle eventKey={2}>
									<i className='fas fa-ruler-combined text-muted'></i>
									<div>
										<strong>{item?.data?.floor_plan_gallery?.length ?? '0'}</strong> floor plans
									</div>
									<i className='fas fa-angle-down text-muted'></i>
								</CustomToggle>
								{item?.data?.floor_plan_gallery?.length > 0 && (
									<Accordion.Collapse eventKey={2}>
										<Grid>
											{item?.data?.floor_plan_gallery?.map((image, i) => (
												<a href={image.image} target='_blank' rel='noopener noreferrer' key={i}>
													<img src={image.image} alt={image['alt-text']} />
												</a>
											))}
										</Grid>
									</Accordion.Collapse>
								)}
							</Accordion>
						</div>
						<Buttons>
							<Button onClick={() => handleShowImageUploadModal(item.id)} title={item?.data?.image_gallery?.length < 20 ? 'Add images' : 'You cannot add more than 20 images'} disabled={item?.data?.image_gallery?.length >= 20}>
								Add Images <i className='fas fa-images'></i>
							</Button>
							<Button variant='outline-primary' onClick={() => handleShowFloorPlanUploadModal(item.id)} title={item?.data?.floor_plan_gallery?.length < 10 ? 'Add floor plans' : 'You cannot add more than 10 floor plans'} disabled={item?.data?.floor_plan_gallery?.length >= 10}>
								Add Floor Plans <i className='fas fa-ruler-combined'></i>
							</Button>
						</Buttons>
					</Item>
				))}
			</Items>
		</Component>
	);
};

export default ImageUploads;
