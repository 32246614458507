import styled from 'styled-components';
import { useMessage } from 'utils/MessageContext';

const Message = styled.div`
	color: white;
	position: sticky;
	top: 0;
	z-index: 9;
	display: grid;
	place-items: center;
	flex: 0 0 auto;
	transition: all 0.5s;
	line-height: 1.25;

	i {
		opacity: 0.6 !important;
	}
`;

const MessageBar = () => {
	const { message } = useMessage();
	const { variant, text } = { ...message };

	const variantIcons = {
		success: 'fa-check-circle',
		warning: 'fa-exclamation-circle',
		danger: 'fa-times-circle',
		primary: 'fa-info-circle',
		info: 'fa-info-circle'
	};

	return (
		<Message style={{ background: `var(--bs-${variant})`, height: !message ? 0 : '3.5rem', padding: !message ? 0 : '0.5rem 1rem' }}>
			<div className='text-center'>
				<i className={`fas ${variantIcons[variant]} me-2`}></i>
				{text}
			</div>
		</Message>
	);
};

export default MessageBar;
