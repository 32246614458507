import { useState } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { StepIndicator } from '../../Components';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

// Steps
import Download from './Steps/1-Download';
import Import from './Steps/2-Import';
import Verify from './Steps/3-Verify';
import Complete from './Steps/4-Complete';

// Utils
import { addCollectionData } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';

const Component = styled.div`
	display: grid;
	gap: 3rem;
`;

const Form = styled.form`
	overflow: hidden;
	padding: 0 0 0.5rem;
`;

const Buttons = styled.div`
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	margin: 2rem 0 0;
`;

const AddCsvData = () => {
	// Component state
	const [currentStep, setCurrentStep] = useState(1);
	const [importedCsv, setImportedCsv] = useState([]);
	const [loading, setLoading] = useState(false);

	// Hooks
	const { site_id, collection_name } = useParams();
	const { data: collectionData } = useSWR(`/api/v1/collections/${site_id}/${encodeURIComponent(collection_name)}`);
	const { name, fields } = { ...collectionData };
	const { setMessage } = useMessage();

	// All steps
	const allSteps = [
		{
			order: 1,
			name: 'Download CSV Template',
			icon: 'download',
			component: Download
		},
		{
			order: 2,
			name: 'Import Customer CSV',
			icon: 'file-import',
			component: Import
		},
		{
			order: 3,
			name: 'Verify CSV Data',
			icon: 'database',
			component: Verify
		},

		{
			order: 4,
			name: 'Complete',
			icon: 'check-circle',
			component: Complete
		}
	];

	// Handlers
	const handleSubmit = async e => {
		setLoading(true);
		e.preventDefault();
		try {
			await addCollectionData({ name, site_id, data: importedCsv });
			setTimeout(() => setLoading(false), 1000);
			setCurrentStep(allSteps.length);
		} catch (error) {
			setMessage({ variant: 'danger', text: error.message });
			setLoading(false);
		}
	};

	// Render
	return (
		<>
			<PageHeader heading={collection_name} subheading={'Add data to an existing collection from a completed CSV template.'} />
			{loading && <LoadingOverlay />}
			<Component>
				<StepIndicator currentStep={currentStep} allSteps={allSteps} />
				<Form onSubmit={e => handleSubmit(e)}>
					{allSteps.map(step => step.order === currentStep && <step.component key={step.order} fields={fields} importedCsv={importedCsv} setImportedCsv={setImportedCsv} collectionData={collectionData} site_id={site_id} />)}
					<Buttons>
						{currentStep !== allSteps.length && (
							<Button variant='secondary' onClick={() => setCurrentStep(prev => prev - 1)} disabled={currentStep === 1}>
								<i className='fas fa-angle-left'></i> Previous
							</Button>
						)}
						{currentStep < allSteps.length - 1 && (
							<Button variant='primary' onClick={() => setCurrentStep(prev => prev + 1)} disabled={currentStep === 2 && importedCsv.length === 0}>
								Next <i className='fas fa-angle-right'></i>
							</Button>
						)}
						{currentStep === allSteps.length - 1 && (
							<Button variant='primary' type='submit'>
								Add new data <i className='fas fa-check-circle'></i>
							</Button>
						)}
					</Buttons>
				</Form>
			</Component>
		</>
	);
};

export default AddCsvData;
