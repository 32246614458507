import Card from 'components/shared/Card';
import PageHeader from 'components/shared/PageHeader';
import SmallWrapper from 'components/shared/SmallWrapper';

const Refresh = () => {
	return (
		<>
			<PageHeader heading={'Refresh Listings'} subheading={"Clear a site's listings cache and refresh it with updated listings data."} />
			<SmallWrapper>
				<Card title={"We've moved!"}>
					This function has been moved to the Website QA Tool. To perform a listings data refresh, use the following link:{' '}
					<a href='https://qa.appfoliowebsites.com/site/find' target='_blank' rel='noopener noreferrer'>
						qa.appfoliowebsites.com/site/find
					</a>
				</Card>
			</SmallWrapper>
		</>
	);
};

export default Refresh;
