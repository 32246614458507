import { config } from 'config/config';

export const createCollection = async payload => {
	try {
		const response = await fetch('/api/v1/collections/property/new', {
			method: 'POST',
			headers: {
				Authorization: `Basic ${config.DUDA_APM_API_STRING}`,
				'Content-Type': 'application/json',
				Accept: 'application/json'
			},
			body: JSON.stringify(payload)
		});
		if (!response.ok) {
			const error = await response.json();
			throw new Error(error.message);
		}
	} catch (error) {
		throw error;
	}
};

export const addCollectionData = async payload => {
	try {
		const response = await fetch('/api/v1/collections/property/data', {
			method: 'POST',
			headers: {
				Authorization: `Basic ${config.DUDA_APM_API_STRING}`,
				'Content-Type': 'application/json',
				Accept: 'application/json'
			},
			body: JSON.stringify(payload)
		});
		if (!response.ok) {
			const error = await response.json();
			throw new Error(error.message);
		}
	} catch (error) {
		throw error;
	}
};

export const dudaUpload = async payload => {
	const { site_id, collection_name, url, row_id } = payload;
	const response = await fetcher(`/api/v1/upload/image/${site_id}/${encodeURIComponent(collection_name)}`, { method: 'POST', body: JSON.stringify({ url, row_id }) });
	return response;
};

export const fetcher = async (...args) => {
	const [url, options] = args;
	try {
		const response = await fetch(url, {
			...options,
			headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...options?.headers }
		});
		const data = await response.json();
		if (!response.ok) {
			throw data;
		}
		return data;
	} catch (error) {
		const { statusText, message } = error;
		const errorText = statusText ?? message;
		if (errorText === 'Unauthorized') {
			window.location = `/login?error=${errorText}`;
		}
		throw error;
	}
};
