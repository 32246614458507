import styled from 'styled-components';

export const UploadZone = styled.div`
	border: 3px dashed #ddd;
	border-radius: 0.5rem;
	padding: 2rem;
	display: grid;
	place-items: center;
	min-height: 200px;
	max-width: 720px;
	margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	align-items: center;
`;

export const Panel = styled.div`
	background: white;
	padding: 1.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	border-radius: 0.5rem;
`;

const StepIndicatorComponent = styled.div`
	display: flex;
	justify-content: space-evenly;

	@media (max-width: 768px) {
		flex-wrap: wrap;
	}
`;

const Border = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	height: 3px;
`;

const Icon = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	margin: 0 auto 1rem;
	color: white;
	font-size: 1.5rem;
	position: relative;
	z-index: 1;
`;

const IconContainer = styled.div`
	position: relative;
`;

const Name = styled.p`
	margin-bottom: 0.5rem;
`;

const Description = styled.div`
	font-size: 0.9rem;
	text-align: left;
`;

const Step = styled.div`
	flex: 1;
	text-align: center;
	color: ${({ $active }) => ($active ? 'var(--bs-primary)' : 'var(--bs-gray-400)')};

	${Border}, ${Icon} {
		background: ${({ $active }) => ($active ? 'var(--bs-primary)' : 'var(--bs-gray-400)')};
	}

	${Name} {
		color: ${({ $active }) => ($active ? 'var(--bs-dark)' : 'var(--bs-gray-400)')};
		font-weight: ${({ $active }) => ($active ? 'bold' : 'normal')};
	}

	@media (max-width: 768px) {
		flex: 0 0 100%;
		display: ${({ $active }) => ($active ? 'block' : 'none')};
	}
`;

export const StepIndicator = ({ currentStep, allSteps }) => {
	return (
		<StepIndicatorComponent>
			{allSteps.map(step => (
				<Step key={step.order} $active={step.order === currentStep ? true : false}>
					<IconContainer>
						<Icon>
							<i className={`fa fa-${step.icon}`}></i>
						</Icon>
						<Border />
					</IconContainer>
					<Name>
						{step.order}. {step.name}
					</Name>
					{step.order === currentStep && <Description className='text-muted'>{step.description}</Description>}
				</Step>
			))}
		</StepIndicatorComponent>
	);
};
