import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Panel } from '../../Components';

const Component = styled.div``;

const FieldRow = styled.div`
	background: var(--lightest);
	padding: 0.5rem;
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	border-bottom: 1px solid #ddd;

	&:not(:first-of-type) {
		cursor: grab;
	}

	&.dragging {
		background: white;
		box-shadow: 0 2px 5px #0003;
		border-radius: 0.5rem;
	}
`;

const Generate = ({ csvFields, setCsvFields }) => {
	// Handlers
	const handleDragEnd = result => {
		if (!result.destination || result.destination.index === 0) {
			return;
		}
		const newFields = [...csvFields];
		const [removed] = newFields.splice(result.source.index, 1);
		newFields.splice(result.destination.index, 0, removed);
		setCsvFields(newFields);
	};

	return (
		<Component>
			<Row>
				<Col lg={6}>
					<Panel className='mb-4'>
						<h5>
							<i className='fas fa-info-circle text-warning me-3'></i>Instructions
						</h5>
						<ol className='mb-0'>
							<li>Drag & drop the fields to the right to specify the order of the columns in the csv template.</li>
						</ol>
					</Panel>
				</Col>
				<Col lg={6}>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId='droppable'>
							{provided => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{csvFields.map((field, i) => (
										<Draggable draggableId={field.name + i} key={field.name + i} index={i} isDragDisabled={field.name === 'Property Name'}>
											{(provided, snapshot) => (
												<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<FieldRow key={field.name + i} className={snapshot.isDragging && 'dragging'}>
														<div>{field.name}</div>
														{field.name !== 'Property Name' && <i className='fas fa-grip-lines text-muted'></i>}
													</FieldRow>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</Col>
			</Row>
		</Component>
	);
};

export default Generate;
