import { useState } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { StepIndicator } from '../Components';
import { ButtonWrapper } from '../Components';

// Steps
import Configure from './Steps/1-Configure';
import Generate from './Steps/2-Generate';
import Complete from './Steps/3-Complete';

const Component = styled.div`
	display: grid;
	gap: 3rem;
`;

const PropertyCollection = () => {
	// Component state
	const [currentStep, setCurrentStep] = useState(1);
	const [csvFields, setCsvFields] = useState([]);

	// All steps
	const allSteps = [
		{
			order: 1,
			name: 'Configure Field Names',
			icon: 'cog',
			component: Configure
		},
		{
			order: 2,
			name: 'Configure Field Order',
			icon: 'file-csv',
			component: Generate
		},
		{
			order: 3,
			name: 'Download & Complete',
			icon: 'check-circle',
			component: Complete
		}
	];

	// Render
	return (
		<>
			<PageHeader heading={'Export CSV Template'} subheading={'Generate a CSV template for customers to populate with data.'} />
			<Component>
				<StepIndicator currentStep={currentStep} allSteps={allSteps} />
				{allSteps.map(step => step.order === currentStep && <step.component key={step.order} csvFields={csvFields} setCsvFields={setCsvFields} />)}
				<ButtonWrapper>
					<Button variant='secondary' onClick={() => setCurrentStep(prev => prev - 1)} disabled={currentStep === 1}>
						<i className='fas fa-angle-left'></i> Previous
					</Button>
					{currentStep < allSteps.length && (
						<Button onClick={() => setCurrentStep(prev => prev + 1)}>
							Next <i className='fas fa-angle-right'></i>
						</Button>
					)}
					{currentStep === allSteps.length && (
						<Button as={Link} to='/'>
							Complete <i className='fas fa-check-circle'></i>
						</Button>
					)}
				</ButtonWrapper>
			</Component>
		</>
	);
};

export default PropertyCollection;
