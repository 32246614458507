import styled from 'styled-components';

import { tidyFileSize } from 'utils/Tidy';
import { Draggable } from 'react-beautiful-dnd';

const CustomButton = styled.button`
	all: unset;
	font-size: 1.5rem;
	transition: 0.2s;
	color: var(--bs-secondary);
`;

const RemoveButton = styled(CustomButton)`
	&:hover,
	&.focus-visible {
		color: var(--bs-danger);
	}
`;

const MainImageButton = styled(CustomButton)`
	&:hover,
	&.focus-visible {
		color: var(--bs-primary);
	}
`;

const DragButton = styled.div`
	font-size: 1.5rem;
	grid-area: drag;
	color: var(--bs-secondary);
	background: var(--bs-gray-300);
	display: grid;
	place-items: center;
`;

const File = styled.div`
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 10rem 1fr auto 2rem;
	grid-template-areas: 'image info buttons drag';
	gap: 1rem;
	background: var(--lightest);
	transition: 0.2s;
	border-radius: 0.5rem;
	overflow: hidden;
	margin: 1rem 0;

	@media (max-width: 576px) {
		grid-template-columns: 10rem 1fr 2rem;
		grid-template-rows: auto auto;
		grid-template-areas: 'image info drag' 'image buttons drag';
		gap: 0 1rem;
	}

	&.main-image {
		color: var(--bs-primary);

		${MainImageButton} {
			color: var(--bs-primary);
		}
	}

	img {
		grid-area: image;
		width: 100%;
		height: 100%;
		min-height: 7rem;
		aspect-ratio: 16 / 9;
		object-fit: cover;
	}
`;

const MainTag = styled.span`
	white-space: nowrap;
	font-size: 0.7rem;
	padding: 0.1rem 0.5rem;
	border-radius: 0.5rem;
	color: white;
	background: var(--bs-primary);
`;

const Info = styled.div`
	grid-area: info;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	align-self: center;

	@media (max-width: 576px) {
		padding-top: 0.5rem;
	}
`;

const Buttons = styled.div`
	grid-area: buttons;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: end;

	@media (max-width: 576px) {
		justify-content: start;
		padding: 0.25rem 0 0.5rem 0;
	}
`;

const FileCard = ({ fileObject, index, mainImageId, handleRemoveImage, handleSetMainImage, mainImageExists }) => {
	const { id, file } = { ...fileObject };
	const showMainImage = mainImageId === id && !mainImageExists;
	const allowSettingMainImage = handleSetMainImage && !mainImageExists;

	return (
		<Draggable key={id} draggableId={id} index={index}>
			{(provided, snapshot) => (
				<File className={`${showMainImage && 'main-image'} ${snapshot.isDragging && 'shadow-lg'}`} ref={provided.innerRef} {...provided.draggableProps}>
					<img src={URL.createObjectURL(file)} alt={file.name} width='100%' />
					<Info>
						{showMainImage && (
							<div className='mb-1'>
								<MainTag>MAIN IMAGE</MainTag>
							</div>
						)}
						<strong>{file.name}</strong>
						<div>
							<small className='text-muted'>{tidyFileSize(file.size)}</small>
						</div>
					</Info>
					<Buttons>
						<RemoveButton type='button' title='Remove image' onClick={() => handleRemoveImage(id)}>
							<i className='far fa-trash-alt'></i>
						</RemoveButton>
						{allowSettingMainImage && (
							<MainImageButton type='button' title='Set as main image' onClick={() => handleSetMainImage(id)}>
								{showMainImage ? <i className='fas fa-star'></i> : <i className='far fa-star'></i>}
							</MainImageButton>
						)}
					</Buttons>
					<DragButton {...provided.dragHandleProps}>
						<i className='fas fa-grip-vertical'></i>
					</DragButton>
				</File>
			)}
		</Draggable>
	);
};

export default FileCard;
