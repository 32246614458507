import { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';
import LoadingOverlay from './LoadingOverlay';

const Component = styled.form`
	max-width: 720px;
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 1rem;
	align-items: center;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
	}
`;

const ImSiteIdInput = ({ redirectUrl, ...props }) => {
	// State
	const [siteId, setSiteId] = useState('');
	const [valid, setValid] = useState(false);

	// Hooks
	const { data: siteIds, isLoading } = useSWR('/api/v1/im/sites/all');
	const navigate = useNavigate();

	useEffect(() => {
		siteId?.length === 8 && siteIds?.includes(siteId) ? setValid(true) : setValid(false);
	}, [siteId, siteIds]);

	// Handlers
	const handleSubmit = async e => {
		e.preventDefault();
		navigate(`${redirectUrl}/${siteId}`);
	};

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<Component onSubmit={e => handleSubmit(e)} {...props}>
			<div>
				<Form.Label>IM Duda Site ID</Form.Label>
				<Form.Control type='text' className='me-2 mb-2' value={siteId} minLength={8} maxLength={8} pattern='[a-zA-Z0-9]{8}$' onChange={e => setSiteId(e.target.value)} required />
				<Form.Text className='text-muted'>
					{siteId.length > 0 ? (
						valid ? (
							<span>
								<i className='fas fa-check-circle text-success me-1'></i> Site ID found.
							</span>
						) : (
							<span>
								<i className='fas fa-times-circle text-danger me-1'></i> Site ID not found.
							</span>
						)
					) : (
						<span>
							<i className='fas fa-info-circle me-1'></i> Enter a unique 8-digit Duda site ID.
						</span>
					)}
				</Form.Text>
			</div>
			<Button type='submit' disabled={!valid}>
				Get Site Info <i className='fas fa-chevron-circle-right'></i>
			</Button>
		</Component>
	);
};

export default ImSiteIdInput;
