import PageHeader from 'components/shared/PageHeader';
import SmallWrapper from 'components/shared/SmallWrapper';
import ImSiteIdInput from 'components/shared/ImSiteIdInput';

const Input = () => {
	return (
		<>
			<PageHeader heading={'AudioEye App'} subheading={'Enable or disable the AudioEye app on a site.'} />
			<SmallWrapper>
				<ImSiteIdInput redirectUrl={'/im/audioeye'} />
			</SmallWrapper>
		</>
	);
};

export default Input;
