import { useState } from 'react';
import PageHeader from 'components/shared/PageHeader';
import { Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { useMessage } from 'utils/MessageContext';

const Component = styled.div`
	display: grid;
	gap: 1.5rem;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: minmax(auto, 50rem) min-content;
	align-items: start;
	gap: 1rem;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
	}
`;

const BlogFeed = () => {
	// Hooks
	const { setMessage } = useMessage();

	// State
	const [url, setUrl] = useState('');
	const [feedUrl, setFeedUrl] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	// Handle submit
	const handleGenerateFeed = () => {
		setIsLoading(true);
		const { hostname } = new URL(url);
		const blogUrl = encodeURIComponent(`${hostname}/feed/rss2`);
		const currentUrl = window.location.port === '5000' ? `http://${window.location.hostname}:3000` : window.location.origin;
		setFeedUrl(`${currentUrl}/xml/blog/${blogUrl}`);
		setTimeout(() => setIsLoading(false), 500);
	};

	// Copy feed URL
	const copyFeedUrl = async () => {
		await navigator.clipboard.writeText(feedUrl);
		setMessage({ variant: 'info', text: 'Feed URL copied to clipboard.' });
	};

	return (
		<>
			<PageHeader heading={'Generate Blog Feed'} subheading={"Generate a feed that is compatible with Duda's blog importer."} />
			{isLoading && <LoadingOverlay />}
			<Component>
				<Wrapper>
					<div>
						<Form.Control className='mb-2' type='url' placeholder='Site URL...' value={url} onChange={e => setUrl(e.target.value)} required />
						<Form.Text>Copy and paste a Duda site URL from your browser's address bar.</Form.Text>
					</div>
					<Button variant='primary' onClick={handleGenerateFeed}>
						Generate feed <i className='fas fa-search'></i>
					</Button>
				</Wrapper>
				{feedUrl && (
					<Wrapper>
						<div>
							<Form.Control className='mb-2' type='text' value={feedUrl} disabled />
							<Form.Text>Copy the feed URL above into the Duda blog importer.</Form.Text>
						</div>
						<Button variant='secondary' type='button' onClick={copyFeedUrl}>
							Copy feed URL <i className='fas fa-copy'></i>
						</Button>
					</Wrapper>
				)}
			</Component>
		</>
	);
};

export default BlogFeed;
