import PageHeader from 'components/shared/PageHeader';
import ApmSiteIdInput from 'components/shared/ApmSiteIdInput';
import SmallWrapper from 'components/shared/SmallWrapper';

const Input = () => {
	return (
		<>
			<PageHeader heading={'Update Database'} subheading={'Connect a website to a new database.'} />
			<SmallWrapper>
				<ApmSiteIdInput redirectUrl={'/listings/database'} />
			</SmallWrapper>
		</>
	);
};

export default Input;
