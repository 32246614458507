import SiteInfoCard from 'components/shared/SiteInfoCard';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import Card from 'components/shared/Card';
import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { useMessage } from 'utils/MessageContext';
import { fetcher } from 'utils/Fetch';

const Component = styled.div`
	display: grid;
	gap: 1.5rem;
`;

const Label = styled.div`
	font-size: 0.9rem;
	color: var(--bs-secondary);
`;

const List = styled.ol`
	margin: 0;
	padding-left: 2rem;
	line-height: 1.75;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	max-height: 30rem;
	overflow: auto;

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const ProductTierSelect = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 1rem;
	max-width: 720px;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
	}
`;

const TotalPageCount = ({ page_count, page_count_status, product_tier }) => {
	const text = product_tier.page_limit ? `limit of ${product_tier?.page_limit} pages` : `page limit`;

	if (page_count_status === 'OK')
		return (
			<div className='text-success'>
				<i className='fas fa-check-circle me-2'></i>
				{page_count}
				<small className='d-block'>
					Within the {text} for {product_tier?.name} sites.
				</small>
			</div>
		);

	if (page_count_status === 'Error')
		return (
			<div className='text-danger'>
				<i className='fas fa-times-circle me-2'></i>
				{page_count}
				<small className='d-block'>
					Exceeds the {text} for {product_tier?.name} sites.
				</small>
			</div>
		);

	if (page_count_status === 'Warning')
		return (
			<div className='text-warning'>
				<i className='fas fa-exclamation-circle me-2'></i>
				{page_count}
				<small className='d-block'>
					At the {text} for {product_tier?.name} sites.
				</small>
			</div>
		);

	if (page_count_status === 'N/A')
		return (
			<div>
				<i className='fas fa-info-circle me-2'></i>
				{page_count}
				<small className='d-block'>Does not have a defined page count limit.</small>
			</div>
		);
};

const Pages = () => {
	// Hooks
	const { site_id } = useParams();
	const { data: siteInfo, mutate } = useSWR(`/api/v1/sites/pages/${site_id}`);
	const { data: tierInfo } = useSWR(`/api/v1/tiers`);
	const { pages, publish_status, site_domain, external_uid, page_count_status } = { ...siteInfo };
	const { static_pages, dynamic_pages } = { ...pages };
	const url_hostname = site_domain ?? `dashboard.appfoliowebsites.com/site/${site_id}`;
	const { setMessage } = useMessage();

	// State
	const [showPages, setShowPages] = useState(false);
	const [newProductTier, setNewProductTier] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	// Data
	const productTier = tierInfo?.find(tier => tier.name === external_uid) || {
		alias: 'Unknown',
		monthly_fee: null,
		name: 'Unknown',
		page_limit: null
	};

	// Handlers
	const handleUpdateProductTier = async e => {
		e.preventDefault();
		setIsLoading(true);
		try {
			await fetcher(`/api/v1/sites/${site_id}`, { method: 'PUT', body: JSON.stringify({ external_uid: newProductTier }) });
			await mutate();
			setMessage({
				variant: 'success',
				text: `Product tier for site ${siteInfo.site_name} was updated successfully & republished.`
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: `Product tier for site ${siteInfo.site_name} was not updated - ${error.message}`
			});
		}
		setNewProductTier('');
		setIsLoading(false);
	};

	return (
		<>
			{isLoading && <LoadingOverlay />}
			<PageHeader heading={'Update Product Tier'} subheading={"Update a site's product tier and view page count info."} />
			<Component>
				{siteInfo ? (
					<>
						<SiteInfoCard siteInfo={siteInfo}>
							<h5 className='m-0'>Page Info</h5>
							<div>
								<Label>Product Tier:</Label>
								<strong>{productTier?.name ?? '-'}</strong>
							</div>
							<div>
								<Label>Page Count Limit:</Label>
								{productTier?.page_limit ?? '-'}
							</div>
							<div>
								<Label>Static Page Count:</Label>
								{static_pages.length ?? '-'}
							</div>
							<div>
								<Label>Dynamic Page Count:</Label>
								{dynamic_pages.length ?? '-'}
							</div>
							<div>
								<Label>Total Page Count:</Label>
								<TotalPageCount page_count={static_pages.length + dynamic_pages.length} page_count_status={page_count_status} product_tier={productTier} />
								{publish_status !== 'PUBLISHED' && <small className='text-secondary'>Count does not include dynamic pages as the site is not published.</small>}
							</div>
							<Button variant='secondary' onClick={() => setShowPages(!showPages)} style={{ width: '15rem' }}>
								{showPages ? 'Hide' : 'Show'} Page Info <i className='fas fa-eye'></i>
							</Button>
						</SiteInfoCard>
						<div>
							<Form.Label>New product tier:</Form.Label>
							<ProductTierSelect>
								<Form.Select value={newProductTier} onChange={e => setNewProductTier(e.target.value)}>
									<option disabled></option>
									<option>S1</option>
									<option>S2</option>
									<option>SE</option>
									<option>Custom</option>
								</Form.Select>
								<Button type='submit' onClick={e => handleUpdateProductTier(e)}>
									Update Product Tier <i className='fas fa-paper-plane'></i>
								</Button>
							</ProductTierSelect>
						</div>
						{showPages && (
							<Card title='Page Info'>
								<Wrapper>
									<div>
										<h5>Static Pages</h5>
										<List>
											{static_pages.map(page => (
												<li key={page.path}>
													<a href={`http://${url_hostname}/${page.path}`} target='_blank' rel='noopener noreferrer'>
														{page.path}
													</a>
												</li>
											))}
										</List>
									</div>
									<div>
										<h5>Dynamic Pages</h5>
										{dynamic_pages.length > 0 ? (
											<List>
												{dynamic_pages.map(page => (
													<li key={page.path}>
														<a href={`http://${url_hostname}/${page.path}`} target='_blank' rel='noopener noreferrer'>
															{page.path}
														</a>
													</li>
												))}
											</List>
										) : (
											<div>No dynamic pages found.</div>
										)}
									</div>
								</Wrapper>
							</Card>
						)}
					</>
				) : (
					<LoadingOverlay />
				)}
			</Component>
		</>
	);
};

export default Pages;
