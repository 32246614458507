import { useLogin } from 'utils/UserContext';
import logo from 'img/appfolio_logo_wh.svg';
import { NavLink } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import styled from 'styled-components';

const Component = styled.aside`
	grid-area: sidebar;
	background: var(--darkest);
	color: white;
	display: grid;
	grid-template-rows: auto 1fr auto;

	@media (max-width: 767px) {
		grid-template-rows: auto;
		grid-auto-flow: column;
		align-items: center;
		justify-content: center;
		overflow-x: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	@media (max-width: 576px) {
		justify-content: start;
	}
`;

const AppInfo = styled.div`
	display: grid;
	justify-content: center;
	text-align: center;
	margin-bottom: 3rem;

	@media (max-width: 767px) {
		display: none;
	}
`;

const Logo = styled.img`
	margin: 4rem auto 1.5rem;
`;

const AppName = styled.h4``;

const NavGroup = styled.div`
	@media (max-width: 767px) {
		display: flex;
	}
`;

const GroupName = styled.div`
	color: var(--bs-secondary);
	font-size: 0.9rem;
	padding: 0.25rem 1rem;
	border-bottom: 1px solid;

	@media (max-width: 767px) {
		display: none;
	}
`;

const NavItem = styled.div`
	color: white;
	display: grid;
	padding: 1rem 1.5rem;
	grid-template-columns: 1.5rem 1fr;
	gap: 1.5rem;
	align-items: center;
	transition: 0.1s;
	position: relative;

	&:hover,
	&:focus {
		color: var(--bs-primary);
	}

	@media (max-width: 767px) {
		grid-template-columns: auto;
	}
`;

const NavMenu = styled.nav`
	display: grid;
	align-content: start;
	gap: 1rem;

	a {
		text-decoration: none !important;

		&.active ${NavItem} {
			background: var(--bs-primary);
			color: white;
		}
	}

	@media (max-width: 767px) {
		grid-auto-flow: column;
		justify-content: center;
		gap: 0;
	}
`;

const ItemName = styled.div`
	@media (max-width: 767px) {
		display: none;
	}
`;

const ItemIcon = styled.i`
	font-size: 1.5rem;
`;

const Logout = styled.div`
	background: var(--bs-dark);
	display: grid;
`;

const LogoutButton = styled.button`
	all: unset;
	color: white;
	display: grid;
	padding: 1rem 1.5rem;
	grid-template-columns: auto 1fr;
	gap: 1.5rem;
	align-items: center;
	transition: 0.1s;

	&:hover,
	&:focus {
		color: var(--bs-primary);
	}

	@media (max-width: 767px) {
		gap: 0;
	}
`;

export default function Sidebar() {
	// User Hooks
	const { user, setUser } = useLogin();

	// On successful logout
	const handleLogout = async () => {
		googleLogout();
		await fetch('/auth/signout');
		setUser(null);
	};

	return (
		<Component>
			<AppInfo>
				<NavLink to='/'>
					<Logo src={logo} alt='AppFolio Logo' width='70' />
				</NavLink>
				<AppName>Nexus API Tool</AppName>
			</AppInfo>
			<NavMenu>
				<NavGroup>
					<GroupName>APM Website Listings</GroupName>
					<NavLink to='listings/database'>
						<NavItem>
							<ItemIcon className='fas fa-database'></ItemIcon>
							<ItemName>Update Database</ItemName>
						</NavItem>
					</NavLink>
					<NavLink to='listings/refresh'>
						<NavItem>
							<ItemIcon className='fas fa-sync-alt'></ItemIcon>
							<ItemName>Refresh Listings</ItemName>
						</NavItem>
					</NavLink>
					{user.isAdmin && (
						<NavLink to='listings/initialize'>
							<NavItem>
								<ItemIcon className='fas fa-plus-circle'></ItemIcon>
								<ItemName>Initialize Listings</ItemName>
							</NavItem>
						</NavLink>
					)}
				</NavGroup>
				<NavGroup>
					<GroupName>APM Website Collections</GroupName>
					<NavLink to='collections/export'>
						<NavItem>
							<ItemIcon className='fas fa-file-export'></ItemIcon>
							<ItemName>Export CSV Template</ItemName>
						</NavItem>
					</NavLink>
					<NavLink to='collections/import'>
						<NavItem>
							<ItemIcon className='fas fa-file-csv'></ItemIcon>
							<ItemName>Import CSV Data</ItemName>
						</NavItem>
					</NavLink>
					<NavLink to='collections/modify'>
						<NavItem>
							<ItemIcon className='fas fa-edit'></ItemIcon>
							<ItemName>Modify Existing Collection</ItemName>
						</NavItem>
					</NavLink>
				</NavGroup>
				<NavGroup>
					<GroupName>APM Website Features</GroupName>
					<NavLink to='site/tier'>
						<NavItem>
							<ItemIcon className='fas fa-sitemap'></ItemIcon>
							<ItemName>Update Product Tier</ItemName>
						</NavItem>
					</NavLink>
					<NavLink to='site/audioeye'>
						<NavItem>
							<ItemIcon className='fas fa-universal-access'></ItemIcon>
							<ItemName>AudioEye App</ItemName>
						</NavItem>
					</NavLink>
				</NavGroup>
				<NavGroup>
					<GroupName>IM Websites</GroupName>
					<NavLink to='im/account'>
						<NavItem>
							<ItemIcon className='fas fa-file-invoice'></ItemIcon>
							<ItemName>Update Account Name</ItemName>
						</NavItem>
					</NavLink>
					<NavLink to='im/audioeye'>
						<NavItem>
							<ItemIcon className='fas fa-universal-access'></ItemIcon>
							<ItemName>AudioEye App</ItemName>
						</NavItem>
					</NavLink>
				</NavGroup>
				<NavGroup>
					<GroupName>APM/IM Blog Actions</GroupName>
					<NavLink to='blog/feed'>
						<NavItem>
							<ItemIcon className='fas fa-blog'></ItemIcon>
							<ItemName>Generate Blog Feed</ItemName>
						</NavItem>
					</NavLink>
				</NavGroup>
			</NavMenu>
			<Logout>
				<LogoutButton onClick={() => handleLogout()}>
					<ItemIcon className='fas fa-sign-out-alt' />
					<ItemName>Log out</ItemName>
				</LogoutButton>
			</Logout>
		</Component>
	);
}
