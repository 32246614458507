import { Row, Col, Button } from 'react-bootstrap';
import { useCSVDownloader, usePapaParse } from 'react-papaparse';
import { useEffect, useState } from 'react';
import { addUnderscores } from 'utils/Tidy';
import { Panel, UploadZone } from '../../Components';

const Complete = ({ csvFields }) => {
	// State
	const [csvOutput, setCsvOutput] = useState();

	// Hooks
	const { CSVDownloader } = useCSVDownloader();
	const { jsonToCSV } = usePapaParse();

	// Effects
	useEffect(() => {
		const fields = csvFields.map(field => addUnderscores(field.name));
		setCsvOutput(jsonToCSV({ fields }));
	}, [csvFields, jsonToCSV]);

	return (
		<Row className='align-items-stretch'>
			<Col lg={6}>
				<Panel className='mb-4'>
					<h5>
						<i className='fas fa-info-circle text-warning me-3'></i>Instructions
					</h5>
					<ol className='mb-0'>
						<li>Download the csv template using the button to the right.</li>
						<li>Send the csv template to your customer to populate with their property data.</li>
					</ol>
				</Panel>
			</Col>
			<Col lg={6}>
				<UploadZone>
					<div className='text-center'>
						<h6 className='mb-3'>Download CSV template and send to customer:</h6>
						<Button as={CSVDownloader} variant='dark' filename='AppFolio Website Property Page Template' data={csvOutput}>
							Download File <i className='fas fa-download'></i>
						</Button>
					</div>
				</UploadZone>
			</Col>
		</Row>
	);
};

export default Complete;
