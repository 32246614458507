import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import SiteInfoCard from 'components/shared/SiteInfoCard';
import SmallWrapper from 'components/shared/SmallWrapper';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useSWR, { mutate } from 'swr';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';

const Wrapper = styled.div`
	display: grid;
	justify-items: start;
	gap: 1rem;
`;

const Details = () => {
	// Hooks
	const { site_id } = useParams();
	const { data: siteInfo, isValidating } = useSWR(`/api/v1/sites/${site_id}`);
	const { setMessage } = useMessage();

	// State
	const [isLoading, setIsLoading] = useState(false);

	// Handlers
	const handleInitListings = async () => {
		setIsLoading(true);
		try {
			await fetcher(`/api/v1/listings/initialize`, { method: 'POST', body: JSON.stringify({ id: site_id }) });
			await mutate(`/api/v1/sites/${site_id}`);
			setMessage({
				variant: 'success',
				text: `The appfolio-listings collection for site ${site_id} was initialized successfully.`
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: `Error - ${error.message}`
			});
		}
		setIsLoading(false);
	};

	return (
		<>
			<PageHeader heading={'Initialize Listings'} subheading={'Create a new appfolio-listings collection in a specific site.'} />
			<SmallWrapper>
				{(isValidating || isLoading) && <LoadingOverlay />}
				{siteInfo && (
					<Wrapper>
						<SiteInfoCard siteInfo={siteInfo} />
						<Button onClick={() => handleInitListings()} disabled={siteInfo?.listings_endpoint}>
							Initialize appfolio-listings collection <i className='fas fa-paper-plane'></i>
						</Button>
					</Wrapper>
				)}
			</SmallWrapper>
		</>
	);
};

export default Details;
