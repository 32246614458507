import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import SiteInfoCard from 'components/shared/SiteInfoCard';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';

const Label = styled.div`
	font-size: 0.9rem;
	color: var(--bs-secondary);
`;

const Details = () => {
	// Hooks
	const { site_id } = useParams();
	const { data, isValidating, mutate } = useSWR(`/api/v1/audioeye/${site_id}?account=APM`);
	const { audioeye } = { ...data };
	const { setMessage } = useMessage();

	// State
	const [isLoading, setIsLoading] = useState(false);

	// Render functions
	const renderStatus = status => {
		let output;
		switch (status) {
			case true:
				output = (
					<>
						<i className='fas fa-check-circle text-success me-2'></i>
						Enabled
					</>
				);
				break;
			case false:
				output = (
					<>
						<i className='fas fa-times-circle text-danger me-2'></i>
						Not Enabled
					</>
				);
				break;
			default:
				output = (
					<>
						<i className='fas fa-times-circle text-danger me-2'></i>
						Not Enabled
					</>
				);
				break;
		}
		return output;
	};

	const renderPlan = status => {
		let output;
		switch (status) {
			case 'Standard':
				output = (
					<>
						<i className='fas fa-check-circle text-success me-2'></i>
						Standard
					</>
				);
				break;
			case 'Unknown':
				output = (
					<>
						<i className='fas fa-times-circle text-danger me-2'></i>
						Unknown
					</>
				);
				break;
			default:
				output = (
					<>
						<i className='fas fa-times-circle text-danger me-2'></i>
						None
					</>
				);
				break;
		}
		return output;
	};

	// Handlers
	const handleToggleAudioEye = async type => {
		setIsLoading(true);
		try {
			await fetcher(`/api/v1/audioeye/${site_id}`, { method: 'PUT', body: JSON.stringify({ type, account: 'APM' }) });
			await mutate();
			setMessage({ variant: 'success', text: `Successfully ${type}d AudioEye app for site ${site_id}` });
		} catch (error) {
			setMessage({ variant: 'danger', text: `Error ${type.slice(0, type.length - 1)}ing AudioEye app - ${error.message}` });
		}
		setIsLoading(false);
	};

	return (
		<>
			<PageHeader heading={'AudioEye App'} subheading={'Enable or disable the AudioEye app on a site.'} />
			{(isLoading || isValidating) && <LoadingOverlay />}
			{data && (
				<SiteInfoCard siteInfo={data}>
					<h5 className='m-0'>AudioEye</h5>
					<div>
						<Label>AudioEye Status:</Label>
						<div>{renderStatus(audioeye.enabled)}</div>
					</div>
					<div>
						<Label>AudioEye Plan:</Label>
						<div>{renderPlan(audioeye.plan)}</div>
					</div>
					{audioeye.enabled ? (
						<Button variant='danger' onClick={() => handleToggleAudioEye('disable')}>
							<i className='fas fa-power-off me-1'></i> Disable AudioEye
						</Button>
					) : (
						<Button onClick={() => handleToggleAudioEye('enable')}>
							<i className='fas fa-power-off me-1'></i> Enable AudioEye
						</Button>
					)}
				</SiteInfoCard>
			)}
		</>
	);
};

export default Details;
