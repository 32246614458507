import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

// Tidy URLs
export function tidyUrl(url) {
	return url ? url.replace('http://', '').replace('https://', '').replace(/\/$/g, '') : '';
}

// Tidy dates
export function tidyDate(date, format = 'MM/DD/YYYY') {
	return date ? dayjs(date).format(format) : '';
}

export function tidyDatePicker(date) {
	return date ? dayjs(date).format('YYYY-MM-DD') : '';
}

export function tidyUTCDate(date) {
	return date ? dayjs(date).utc().format('MM/DD/YYYY') : '';
}

export function tidyTime(date) {
	return date ? dayjs(date).format('h:mm A') : '';
}

export function tidyDateTime(date) {
	return date ? dayjs(date).format('MMMM D YYYY @ h:mm A') : '';
}

// Tidy text
export function tidyCapitalize(text) {
	return text
		.toLowerCase()
		.split(/ |_/)
		.map(word => word[0].toUpperCase() + word.slice(1))
		.join(' ');
}

export function tidyUnderscores(text) {
	return text
		.toLowerCase()
		.split('_')
		.map(word => word[0].toUpperCase() + word.slice(1))
		.join(' ');
}

export function addUnderscores(text) {
	return text
		.toLowerCase()
		.replace(/[^a-zA-Z0-9]/g, ' ')
		.replace(/\s\s+/g, ' ')
		.split(' ')
		.join('_');
}

export function addDashes(text) {
	return text
		.toLowerCase()
		.replace(/[^a-zA-Z0-9]/g, ' ')
		.replace(/\s\s+/g, ' ')
		.split(' ')
		.join('-');
}

export function tidyDashes(text) {
	const withSpaces = text.toLowerCase().split('-').join(' ');
	return withSpaces[0].toUpperCase() + withSpaces.slice(1);
}

export function tidyParagraphs(text) {
	return text.split('\n').map((paragraph, i) => <p key={i}>{paragraph}</p>);
}

export function tidyHTMLParagraphs(text) {
	return text.split('<br />').map((paragraph, i) => <p key={i}>{paragraph}</p>);
}

// Tidy email addresses
export function tidyUserEmail(email) {
	return email
		.split('@')[0]
		.replace('.', ' ')
		.split(' ')
		.map(word => word[0].toUpperCase() + word.slice(1))
		.join(' ');
}

// Tidy database strings
export function tidyDatabase(url) {
	if (!url) return;
	const urlObject = new URL(url);
	return urlObject.hostname;
}

export function tidyPropertyGroup(url) {
	if (!url) return;
	return new URL(url).searchParams.get('property_lists[]');
}

// Tidy file sizes
export function tidyFileSize(bytes) {
	const kilo = 1024;
	const sizes = ['Bytes', 'KB', 'MB'];
	const i = Math.floor(Math.log(bytes) / Math.log(kilo));
	return `${parseFloat((bytes / Math.pow(kilo, i)).toFixed(2))} ${sizes[i]}`;
}
