import styled from 'styled-components';

const Component = styled.div`
	position: fixed;
	inset: 0;
	display: grid;
	place-items: center;
	background: #fffe;
	z-index: 999;
	backdrop-filter: blur(3px);
`;

const Spinner = styled.div`
	width: 5rem;
	height: 5rem;
`;

const LoadingOverlay = ({ ...props }) => {
	return (
		<Component {...props} onClick={e => e.preventDefault()}>
			<Spinner className='spinner-border text-primary' />
		</Component>
	);
};

export default LoadingOverlay;
