import { useState } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import Confirmation from 'components/shared/Confirmation';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import SmallWrapper from 'components/shared/SmallWrapper';
import SiteInfoCard from 'components/shared/SiteInfoCard';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useLogin } from 'utils/UserContext';

const SiteInfo = styled.form`
	display: grid;
	gap: 1.5rem;

	@media (max-width: 576px) {
		gap: 1rem;
	}
`;

const Details = () => {
	// State
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [accountName, setAccountName] = useState('');
	const [isFetching, setIsFetching] = useState(false);
	const [isDemo, setIsDemo] = useState(false);

	// Hooks
	const { user } = useLogin();
	const { isAdmin } = { ...user };
	const { site_id } = useParams();
	const { data: siteInfo, mutate, isLoading } = useSWR(`/api/v1/im/sites/${site_id}`);
	const { site_name } = { ...siteInfo };
	const { setMessage } = useMessage();

	// Handlers
	const verifyAccountName = async () => {
		const { im_account_name, is_valid } = await fetcher(`/api/v1/im/verify/${accountName}${isDemo ? '?demo=true' : ''}`);
		if (!is_valid) throw new Error(`${im_account_name} is not a valid AppFolio IM database name.`);
	};

	const pushNewAccountName = async () => {
		// Serialize payload
		const body = { im_account_name: accountName };

		// PUT new details
		await fetcher(`/api/v1/im/account/${site_id}`, { method: 'PUT', body: JSON.stringify(body) });
	};

	const handleUpdateAccountName = async () => {
		setShowConfirmation(false);
		setIsFetching(true);
		try {
			await verifyAccountName();
			await pushNewAccountName();
			await mutate();
			setMessage({
				variant: 'success',
				text: `The account name for site ${site_name} was updated successfully.`
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: `The account name for site ${site_name} was not updated - ${error.message}`
			});
		}
		setAccountName('');
		setIsDemo(false);
		setIsFetching(false);
	};

	return (
		<>
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={`update the AppFolio IM account name to ${accountName}?`} confirmFunction={handleUpdateAccountName} />
			<PageHeader heading={'Update Account Name'} subheading={'Update the account name.'} />
			{(isLoading || isFetching) && <LoadingOverlay />}
			{siteInfo && (
				<SmallWrapper>
					<SiteInfo
						onSubmit={e => {
							e.preventDefault();
							setShowConfirmation(true);
						}}>
						<SiteInfoCard siteInfo={siteInfo} product='IM' />
						<div>
							<Form.Label>New AppFolio IM Account Name:</Form.Label>
							<InputGroup>
								<InputGroup.Text>app.{isDemo && 'demo.'}appfolioim.com/</InputGroup.Text>
								<Form.Control type='text' value={accountName} onChange={e => setAccountName(e.target.value.toLowerCase())} required />
							</InputGroup>
						</div>
						{isAdmin && (
							<div>
								<Form.Check type='switch' id='is-demo' label='Demo IM account?' checked={isDemo} onChange={() => setIsDemo(!isDemo)} />
							</div>
						)}
						<div>
							<Button type='submit'>
								Update Account Name <i className='fas fa-paper-plane'></i>
							</Button>
						</div>
					</SiteInfo>
				</SmallWrapper>
			)}
		</>
	);
};

export default Details;
