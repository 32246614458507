import CollectionTable from 'components/shared/CollectionTable';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';
import { formatCollectionData } from 'utils/Collections';

import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';

const Component = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const Content = styled.div`
	background: white;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	display: grid;
	grid-template-columns: auto auto auto 1fr;
	padding: 1rem;
	gap: 2rem;

	@media (max-width: 767px) {
		grid-template-columns: 100%;
		gap: 0.5rem;
	}
`;

const Field = styled.div`
	color: var(--bs-secondary);
	font-size: 0.9rem;
`;

const Value = styled.div``;

const Note = styled.div`
	padding: 1rem;
	border-radius: 0.5rem;
	background: white;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
`;

const ViewCollectionData = () => {
	// Hooks
	const { site_id, collection_name } = useParams();
	const { data: collectionData, mutate, isLoading, isValidating } = useSWR(`/api/v1/collections/${site_id}/${encodeURIComponent(collection_name)}`);
	const { setMessage } = useMessage();

	// Table data
	const { name, headers, values } = formatCollectionData(collectionData);
	const count = headers?.length;

	// Handlers
	const handleDuplicateRow = async index => {
		const { data } = collectionData?.values[index];

		try {
			await fetcher(`/api/v1/collections/duplicate-row/${site_id}/${encodeURIComponent(collection_name)}`, { method: 'POST', body: JSON.stringify(data) });
			await mutate();
			setMessage({ variant: 'success', text: 'Successfully duplicated row at the end of this collection' });
		} catch (error) {
			setMessage({ variant: 'danger', text: 'Error duplicating collection row' });
		}
	};

	return (
		<>
			<PageHeader heading={'View Collection Data'} subheading={'View all data for a specific collection.'} />
			{isLoading || isValidating ? (
				<LoadingOverlay />
			) : (
				collectionData && (
					<Component>
						<Note>
							<h5>
								<i className='fas fa-info-circle text-info me-2'></i>Note
							</h5>
							<ul className='mb-0'>
								<li>You can duplicate a data row using the button on the right of in each row. The new data will appear at the end of the collection.</li>
								<li>To edit, delete or reorder data rows, modify the collection directly from within the Duda editor.</li>
							</ul>
						</Note>
						<Content>
							<div>
								<Field>Collection Name:</Field>
								<Value>
									<strong>{name}</strong>
								</Value>
							</div>
							<div>
								<Field>Item Count:</Field>
								<Value>
									<Value>{collectionData.values.length}</Value>
								</Value>
							</div>
							<div>
								<Field>Field Names:</Field>
								<Value>{collectionData.fields.map(field => field.name).join(', ')}</Value>
							</div>
						</Content>
						<CollectionTable headers={headers} values={values} count={count} isEditable={true} handleDuplicateRow={handleDuplicateRow} site_id={site_id} collection_name={collection_name} />
					</Component>
				)
			)}
		</>
	);
};

export default ViewCollectionData;
