import { useState } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { StepIndicator } from '../Components';

// Steps
import Import from './Steps/1-Import';
import Verify from './Steps/2-Verify';
import Details from './Steps/3-Details';
import Complete from './Steps/4-Complete';

// Utils
import { createCollection, addCollectionData } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';

const Component = styled.div`
	display: grid;
	gap: 3rem;
`;

const Form = styled.form`
	overflow: hidden;
	padding: 0 0 0.5rem;
`;

const Buttons = styled.div`
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	margin: 2rem 0 0;
`;

const ImportCsvData = () => {
	// Component state
	const [currentStep, setCurrentStep] = useState(1);
	const [csvFields, setCsvFields] = useState([]);
	const [importedCsv, setImportedCsv] = useState([]);
	const [collectionData, setCollectionData] = useState({ site_id: '', fields: [], name: '' });
	const [loading, setLoading] = useState(false);

	// Hooks
	const { setMessage } = useMessage();

	// All steps
	const allSteps = [
		{
			order: 1,
			name: 'Import Customer CSV',
			icon: 'file-import',
			component: Import
		},
		{
			order: 2,
			name: 'Verify CSV Data',
			icon: 'database',
			component: Verify
		},
		{
			order: 3,
			name: 'Configure Collection',
			icon: 'sliders-h',
			component: Details
		},
		{
			order: 4,
			name: 'Complete',
			icon: 'check-circle',
			component: Complete
		}
	];

	// Handlers
	const handleSubmit = async e => {
		setLoading(true);
		e.preventDefault();
		const { name, site_id } = collectionData;
		try {
			await createCollection(collectionData);
			await addCollectionData({ name, site_id, data: importedCsv });
			setTimeout(() => setLoading(false), 1000);
			setCurrentStep(allSteps.length);
		} catch (error) {
			setMessage({ variant: 'danger', text: error.message });
			setLoading(false);
		}
	};

	// Render
	return (
		<>
			<PageHeader heading={'Import CSV Data'} subheading={'Import data to a new collection from a completed CSV template.'} />
			{loading && <LoadingOverlay />}
			<Component>
				<StepIndicator currentStep={currentStep} allSteps={allSteps} />
				<Form onSubmit={e => handleSubmit(e)}>
					{allSteps.map(step => step.order === currentStep && <step.component key={step.order} csvFields={csvFields} setCsvFields={setCsvFields} importedCsv={importedCsv} setImportedCsv={setImportedCsv} collectionData={collectionData} setCollectionData={setCollectionData} />)}
					<Buttons>
						{currentStep !== allSteps.length && (
							<Button variant='secondary' onClick={() => setCurrentStep(prev => prev - 1)} disabled={currentStep === 1}>
								<i className='fas fa-angle-left'></i> Previous
							</Button>
						)}
						{currentStep < allSteps.length - 1 && (
							<Button variant='primary' onClick={() => setCurrentStep(prev => prev + 1)} disabled={currentStep === 1 && importedCsv.length === 0}>
								Next <i className='fas fa-angle-right'></i>
							</Button>
						)}
						{currentStep === allSteps.length - 1 && (
							<Button variant='primary' type='submit' disabled={!collectionData.site_id || !collectionData.name}>
								Create collection <i className='fas fa-check-circle'></i>
							</Button>
						)}
					</Buttons>
				</Form>
			</Component>
		</>
	);
};

export default ImportCsvData;
