import { useEffect, useState } from 'react';
import styled from 'styled-components';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import ImageUploads from './ImageUploads';
import { useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import MessageBar from 'components/shared/MessageBar';

const Component = styled.div`
	&.wrapper {
		max-width: 1280px;
		margin: 0 auto;
		padding: 1.5rem;

		@media (max-width: 767px) {
			padding: 1rem;
		}
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;

	@media (max-width: 991px) {
		flex-direction: column-reverse;
		gap: 1rem;
	}
`;

const CustomerLink = styled.a`
	display: block;
	background: white;
	padding: 0.5rem 1.5rem;
	border-radius: 0.5rem;
	box-shadow: rgb(0 0 0 / 8%) 0px 0.125rem 0.25rem;
	text-decoration: none !important;
	cursor: pointer;
`;

const AddImages = () => {
	// State
	const [isLoading, setIsLoading] = useState(true);

	// JWT in public view
	const { pathname } = useLocation();
	const isPublic = pathname.includes('/public');
	const { data: jwt } = useSWR(isPublic ? '/auth/jwt' : null);

	// Hooks
	const { site_id, collection_name } = useParams();
	const { data: collectionData, isValidating, mutate } = useSWR(isPublic ? (jwt ? `/api/v1/collections/${site_id}/${encodeURIComponent(collection_name)}` : null) : `/api/v1/collections/${site_id}/${encodeURIComponent(collection_name)}`);

	// Effects
	useEffect(() => {
		isValidating ? setIsLoading(true) : setIsLoading(false);
	}, [isValidating]);

	return (
		<>
			{isPublic && <MessageBar />}
			<Component className={isPublic && 'wrapper'}>
				<Header>
					<PageHeader heading={'Add Images'} subheading={'Bulk add images & image collections to an existing collection.'} />
					{!isPublic && (
						<CustomerLink href={`/public${pathname}`} target='_blank' rel='noopener noreferrer'>
							Customer Upload Link <i className='fas fa-external-link-alt ms-1'></i>
						</CustomerLink>
					)}
				</Header>
				{isLoading && <LoadingOverlay />}
				{collectionData && <ImageUploads data={collectionData} mutate={mutate} />}
			</Component>
		</>
	);
};

export default AddImages;
